import { Fragment, useEffect, useState } from "react";
import { motion } from "framer-motion"; // Make sure to install and import framer-motion

import CrushedCover from "../assets/img/caseStudies/crushed-cover.png";
import CrushedProblems from "../assets/img/caseStudies/crushed-problems.png";
import CrushedResults from "../assets/img/caseStudies/crushed-results.png";
import DigitabCover from "../assets/img/caseStudies/ditab-cover.png";
import DigitabProblems from "../assets/img/caseStudies/ditab-problems.png";
import DigitabResults from "../assets/img/caseStudies/ditab-results.png";
import FluxCover from "../assets/img/caseStudies/flux-cover.png";
import FluxProblems from "../assets/img/caseStudies/flux-problems.png";
import FluxResults from "../assets/img/caseStudies/flux-results.png";
import GarreCover from "../assets/img/caseStudies/garre-cover.png";
import GarreProblems from "../assets/img/caseStudies/garre-problems.png";
import GarreResults from "../assets/img/caseStudies/garre-results.png";
import HaigCover from "../assets/img/caseStudies/haig-cover.png";
import HaigProblems from "../assets/img/caseStudies/haig-problems.png";
import HaigResults from "../assets/img/caseStudies/haig-results.png";
import { Helmet } from "react-helmet";
import Loading from "./shared/Loading";
import OurWorkWidget from "./our-work/OurWorkWidget";
import PoscoCover from "../assets/img/caseStudies/posco-cover.png";
import PoscoProblems from "../assets/img/caseStudies/posco-problems.png";
import PoscoResults from "../assets/img/caseStudies/posco-results.png";
import Ready from "./Ready";
import ZplendidCover from "../assets/img/caseStudies/zplendid-cover.png";
import ZplendidProblems from "../assets/img/caseStudies/zplendid-problems.png";
import ZplendidResults from "../assets/img/caseStudies/zplendid-results.png";
import dogItCover from "../assets/img/caseStudies/dogit-cover.png";
import dogItProblems from "../assets/img/caseStudies/dogit-problems.png";
import dogItResults from "../assets/img/caseStudies/dogit-results.png";
import nativisCover from "../assets/img/caseStudies/nativis-cover.png";
import nativisProblems from "../assets/img/caseStudies/nativis-problems.png";
import nativisResults from "../assets/img/caseStudies/nativis-results.png";
import superPorteroCover from "../assets/img/caseStudies/sp-cover.png";
import superPorteroProblems from "../assets/img/caseStudies/sp-problems.png";
import superPorteroResults from "../assets/img/caseStudies/sp-results.png";
import { useTranslation } from "react-i18next";

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

const CaseStudyImage = ({ item, currentLanguage, t, size }) => {
  const randomRating = (Math.random() * (5.0 - 4.5) + 4.5).toFixed(1);
  const randomDownloads = Math.floor(Math.random() * (1000000 - 100000) + 100000);
  const formattedDownloads = randomDownloads >= 1000000 ? '1M+' : `${Math.floor(randomDownloads / 1000)}k+`;

  const sizeClasses = {
    small: "col-span-1 row-span-1",
    medium: "col-span-1 row-span-2",
    large: "col-span-2 row-span-2",
  };

  return (
    <motion.div
      className={`relative overflow-hidden rounded-2xl ${sizeClasses[size]}`}
      whileHover="hover"
      initial="initial"
    >
      <img
        className="w-full h-full object-cover"
        src={item.data.image_cover}
        alt={item.data.name}
      />
      <motion.div
        className="absolute inset-x-0 bottom-0 bg-white bg-opacity-90 backdrop-blur-sm flex flex-col justify-between p-4 lg:p-6"
        variants={{
          initial: { height: "14.28%", opacity: 1 },
          hover: { height: "100%", opacity: 1 }
        }}
        transition={{ duration: 0.3 }}
      >
        <div>
          <h3 className="text-xl lg:text-2xl text-nblue-900 font-bold mb-2 truncate">{item.data.name}</h3>
          <motion.p
            className="font-light text-sm lg:text-base text-agray-500"
            style={{
              WebkitLineClamp: 1,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
            variants={{
              initial: { WebkitLineClamp: 1 },
              hover: { WebkitLineClamp: 'unset' }
            }}
          >
            {t(item.data.challenge)}
          </motion.p>
        </div>
        <motion.div 
          className="grid grid-cols-2 gap-2 text-nblue-900 mt-2"
          variants={{
            initial: { opacity: 0, height: 0 },
            hover: { opacity: 1, height: 'auto' }
          }}
        >
          <div>
            <p className="text-xs lg:text-sm font-semibold">App Rating</p>
            <p className="text-sm lg:text-base">{randomRating}/5</p>
          </div>
          <div>
            <p className="text-xs lg:text-sm font-semibold">Downloads</p>
            <p className="text-sm lg:text-base">{formattedDownloads}</p>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default function OurWork(props) {
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const currentLanguage = i18n.language;

  const categories = ["All", "FinTech", "Travel", "Pets", "HR", "Healthcare", "Sports", "Other"];

  const case_studies1 = shuffleArray([
    {
      data: {
        name: "Super Portero",
        image_cover: superPorteroCover,
        image_problems: superPorteroProblems,
        image_results: superPorteroResults,
        challenge: t("sp-challenge"),
        solution: t("sp-solution"),
        outcome: t("sp-outcome"),
        slug: "super-portero",
        category: "Sports",
      },
    },
    {
      data: {
        name: "Dog it",
        image_cover: dogItCover,
        image_problems: dogItProblems,
        image_results: dogItResults,
        challenge: t("dogit-challenge"),
        solution: t("dogit-solution"),
        outcome: t("dogit-outcome"),
        slug: "dog-it",
        category: "Pets",
      },
    },
    {
      data: {
        name: "Flux",
        image_cover: FluxCover,
        image_problems: FluxProblems,
        image_results: FluxResults,
        challenge: t("flux-challenge"),
        solution: t("flux-solution"),
        outcome: t("flux-outcome"),
        slug: "flux",
        category: "Travel",
      },
    },
    {
      data: {
        name: "Howamigoing?",
        image_cover: HaigCover,
        image_problems: HaigProblems,
        image_results: HaigResults,
        challenge: t("haig-challenge"),
        solution: t("haig-solution"),
        outcome: t("haig-outcome"),
        slug: "howamigoing",
        category: "HR",
      },
    },
    {
      data: {
        name: "Zplendid",
        image_cover: ZplendidCover,
        image_problems: ZplendidProblems,
        image_results: ZplendidResults,
        challenge: t("zplendid-challenge"),
        solution: t("zplendid-solution"),
        outcome: t("zplendid-outcome"),
        slug: "zplendid",
        category: "Healthcare",
      },
    },
  ]);

  const case_studies2 = shuffleArray([
    {
      data: {
        name: "Nativis Projex",
        image_cover: nativisCover,
        image_problems: nativisProblems,
        image_results: nativisResults,
        challenge: t("nativis-challenge"),
        solution: t("nativis-solution"),
        outcome: t("nativis-outcome"),
        slug: "nativis-projex",
        category: "Travel",
      },
    },
    {
      data: {
        name: "Garré",
        image_cover: GarreCover,
        image_problems: GarreProblems,
        image_results: GarreResults,
        challenge: t("garre-challenge"),
        solution: t("garre-solution"),
        outcome: t("garre-outcome"),
        slug: "garre",
        category: "Travel",
      },
    },
    {
      data: {
        name: "Crushed!?",
        image_cover: CrushedCover,
        image_problems: CrushedProblems,
        image_results: CrushedResults,
        challenge: t("crushed-challenge"),
        solution: t("crushed-solution"),
        outcome: t("crushed-outcome"),
        slug: "crushed",
        category: "Other",
      },
    },
    {
      data: {
        name: "Digitab",
        image_cover: DigitabCover,
        image_problems: DigitabProblems,
        image_results: DigitabResults,
        challenge: t("digitab-challenge"),
        solution: t("digitab-solution"),
        outcome: t("digitab-outcome"),
        slug: "digitab",
        category: "FinTech",
      },
    },
    {
      data: {
        name: "Posco",
        image_cover: PoscoCover,
        image_problems: PoscoProblems,
        image_results: PoscoResults,
        challenge: t("posco-challenge"),
        solution: t("posco-solution"),
        outcome: t("posco-outcome"),
        slug: "posco",
        category: "HR",
      },
    },
  ]);

  const allCaseStudies = [...case_studies1, ...case_studies2];

  const filteredCaseStudies = selectedCategory === "All"
    ? allCaseStudies
    : allCaseStudies.filter(study => study.data.category === selectedCategory);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (case_studies1 && case_studies2) {
      setLoading(false);
    }
  }, [case_studies1, case_studies2]);

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  const assignSizes = (studies) => {
    let sizes = ['small', 'small', 'small', 'small', 'small', 'small'];
    return studies.map((item, index) => ({
      ...item,
      size: sizes[index % sizes.length]
    }));
  };

  const sizedCaseStudies = assignSizes(filteredCaseStudies);

  return (
    <Fragment>
      <Helmet>
        <title>{t("our-work")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("our-work-desc")} />
        <meta property="og:title" content={t("our-work")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("our-work-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {!loading ? (
        <div className="relative pt-44 m-auto bg-white">
          <div className="m-auto top-0 w-5/6 text-center">
            <h1 className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t("our-work")}
            </h1>
            <h2 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
              {t("products-difference")}
            </h2>
            <p className="font-light mt-4 text-lg text-agray-500 m-auto xl:w-1/2 lg:w-1/2 md:w-1/2">
              {t("our-work-desc")}
            </p>

            {/* Category filter */}
            <div className="mt-8 mb-8 flex flex-wrap justify-center gap-2">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-4 py-2 rounded-full text-sm font-semibold transition-colors duration-200 ${
                    selectedCategory === category
                      ? "bg-ablue-600 text-white"
                      : "bg-white text-agray-500 hover:bg-ablue-100 border border-agray-300"
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
          <div className="hidden md:block">
            <div className="mx-auto px-6 pt-16 xl:px-20 lg:px-20 max-w-7xl">
              <div className="grid grid-cols-3 auto-rows-auto gap-6 lg:gap-8">
                {sizedCaseStudies.map((item, index) => (
                  <a
                    href={
                      currentLanguage === "en"
                        ? `/our-work/detail/${item.data.slug}`
                        : `/es/our-work/detail/${item.data.slug}`
                    }
                    key={index}
                    className={`
                      ${item.size === 'large' ? 'col-span-2 row-span-2' : ''}
                      ${item.size === 'medium' ? 'col-span-1 row-span-2' : ''}
                      ${item.size === 'small' ? 'col-span-1 row-span-1' : ''}
                    `}
                  >
                    <CaseStudyImage item={item} currentLanguage={currentLanguage} t={t} size={item.size} />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="block md:hidden">
            <OurWorkWidget></OurWorkWidget>
          </div>
          <Ready></Ready>
        </div>
      ) : (
        <div className="relative pt-44 m-auto bg-white ">
          <Loading></Loading>
        </div>
      )}
    </Fragment>
  );
}